/* Merriweather font */

@font-face {
    font-family: 'Merriweather';
    src: url('./Merriweather/Merriweather-Regular.ttf');
}
@font-face {
    font-family: 'Merriweather Italic';
    src: url('./Merriweather/Merriweather-Italic.ttf');
    font-style: italic;
}
@font-face {
    font-family: 'Merriweather Italic Bold';
    src: url('./Merriweather/Merriweather-BoldItalic.ttf');
    font-style: italic;
}
@font-face {
    font-family: 'Merriweather Light';
    src: url('./Merriweather/Merriweather-Light.ttf');
    font-weight: lighter
}
@font-face {
    font-family: 'Merriweather Bold';
    src: url('./Merriweather/Merriweather-Bold.ttf');
    font-weight: bold;
}
@font-face {
    font-family: 'Merriweather Italic Light';
    src: url('./Merriweather/Merriweather-LightItalic.ttf');
}

/* Poppins Font */

@font-face {
    font-family: 'Poppins';
    src: url('./Poppins/Poppins-Regular.ttf');
}
@font-face {
    font-family: 'Poppins Italic';
    src: url('./Poppins/Poppins-Italic.ttf');
}
@font-face {
    font-family: 'Poppins Light';
    src: url('./Poppins/Poppins-Light.ttf');
}
@font-face {
    font-family: 'Poppins Thin';
    src: url('./Poppins/Poppins-Thin.ttf');
}
@font-face {
    font-family: 'Poppins Extra Light';
    src: url('./Poppins/Poppins-ExtraLight.ttf');
}
@font-face {
    font-family: 'Poppins Bold';
    src: url('./Poppins/Poppins-Bold.ttf');
}
@font-face {
    font-family: 'Poppins Extra Bold';
    src: url('./Poppins/Poppins-ExtraBold.ttf');
}
@font-face {
    font-family: 'Poppins Medium';
    src: url('./Poppins/Poppins-Medium.ttf');
}
@font-face {
    font-family: 'Poppins SemiBold';
    src: url('./Poppins/Poppins-SemiBold.ttf');
}
