a {
    color: inherit;
    text-decoration: none;
  }
  
  * {
    box-sizing: border-box;
  }
  
  .places-container {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    width: 300px;
  }
  
  .map-container {
    width: 100%;
    height: 311px;
  }
  
  .combobox-input {
    width: 100%;
    padding: 0.5rem;
  }
  .gm-style-iw-d {
    overflow: unset !important
  }
  .gm-style .gm-style-iw-c {
     background: none; 
    border-radius: 0;
  }
  .gm-style .gm-style-iw-tc {
    filter: none !important;
    box-shadow: none !important;
  }
  .gm-ui-hover-effect>span {
    display: none !important;
  }
  .gm-style .gm-style-iw-tc::after{
    background: #714061;
  }
  .gm-ui-hover-effect{
    visibility: hidden;
  }